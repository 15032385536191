/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
svg {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #444444;
  font-size: 14px;
  line-height: 1.5;
  background-color: #F7F7F7;
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: initial;
}
body a {
  text-decoration: none;
}
body .text-uppercase {
  text-transform: uppercase;
}
body .text-right {
  text-align: right;
}
body .text-left {
  text-align: left;
}
body .text-center {
  text-align: center;
}
body .relative {
  position: relative;
}
body > div[data-rbd-draggable-id] {
  z-index: 9000000 !important;
}
body > div[data-rbd-draggable-id] .option {
  background: #ffffff;
  border: 1px solid #ECECEC;
  padding: 5px 10px 3px;
  border-radius: 2px;
}
body .load-anchor {
  min-height: 1px;
  width: 100%;
  margin-top: 10px;
}
body .form-group {
  margin-bottom: 20px;
}
body .form-group label[for] {
  font-weight: 500;
  font-size: 1em;
  display: block;
  margin-bottom: 5px;
}
body .form-group label[for] span {
  color: #D44638;
  margin-left: 5px;
  display: inline-flex;
}
body .form-group .custom-input {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  height: 18px;
  font-size: 1.05em;
  line-height: 1.3;
}
body .form-group .custom-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
body .form-group .custom-input .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ECECEC;
  background: #ffffff;
  border-radius: 2px;
}
body .form-group .custom-input.normal-text {
  text-transform: none;
}
body .form-group .custom-input[data-type="radio"] .checkmark {
  border-radius: 50%;
}
body .form-group .custom-input[data-type="radio"] .checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #33A913;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
body .form-group .custom-input[data-type="radio"] input:checked ~ .checkmark:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
body .form-group .custom-input[data-type="checkbox"] .checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: 1px solid #33A913;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
body .form-group .custom-input[data-type="checkbox"] input:checked ~ .checkmark:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
body .form-group .flexbox:last-child .custom-input,
body .form-group .flexbox:nth-last-child(2) .custom-input {
  margin-bottom: 0;
}
body .form-group .input-field,
body .form-group .react-datepicker-wrapper input {
  display: block;
  border: 1px solid #ECECEC;
  font-family: 'Roboto', sans-serif;
  border-radius: 2px;
  padding: 8px 10px;
  width: 100%;
  background-color: #ffffff;
  height: 35px;
  font-size: 1em;
  font-weight: 300;
  z-index: 51;
  resize: none;
  position: relative;
  line-height: 1.4;
  -webkit-transition: border 0.2s ease 0s;
  -moz-transition: border 0.2s ease 0s;
  -ms-transition: border 0.2s ease 0s;
  -o-transition: border 0.2s ease 0s;
  transition: border 0.2s ease 0s;
  outline: none;
  box-shadow: none;
}
body .form-group textarea.input-field {
  height: 110px;
}
body .form-group .input-field:focus,
body .form-group .input-field:active {
  border: 1px solid #A8A8A8;
}
body .form-group .input-field[disabled] {
  pointer-events: none;
  background-color: hsl(0, 0%, 95%);
  color: #969595;
}
body .form-group .input-select div[class*="-control"] {
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #ECECEC;
  min-height: auto;
  font-size: 1em;
}
body .form-group .input-select div[class*="-control"] > div:first-child {
  position: relative;
}
body .form-group .input-select div[class*="-control"] > div:last-child > span {
  display: none;
}
body .form-group .input-select div[class*="-control"] > div:last-child > span + div svg {
  fill: #33A913;
}
body .form-group .input-select div[class*="-menu"] {
  z-index: 100;
  margin: 0;
  border-radius: 0 0 2px;
  top: calc(100% - 1px);
  box-shadow: none;
  border: 1px solid #ECECEC;
}
body .form-group .input-select div[class*="-menu"] div[class*="-MenuList"] {
  padding: 0;
}
body .form-group .input-select div[class*="-menu"] div[class*="-MenuList"] div[class*="-option"] {
  padding: 6px 12px;
  font-size: 1em;
  margin-bottom: 3px;
}
body .form-group .input-select div[class*="-menu"] div[class*="-MenuList"] div[class*="-option"]:last-child {
  margin-bottom: 0;
}
body .form-group .input-select div[class*="-menu"] div[class*="-MenuList"] div[class*="-1n7v3ny-option"] {
  background-color: rgba(51, 169, 19, 0.15);
}
body .form-group .input-select div[class*="-menu"] div[class*="-MenuList"] div[class*="-9gakcf-option"] {
  background-color: rgba(51, 169, 19, 0.25);
  color: #444444;
  font-weight: 500;
}
body .btn {
  display: inline-block;
  padding: 10px 15px;
  height: 35px;
  margin: 0;
  width: auto;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  position: relative;
  font-weight: 500;
  border: 0;
  border-radius: 2px;
  margin: 0 5px;
  outline: none;
  color: #33A913;
  border: 1px solid #ECECEC;
  background: #ffffff;
}
body .btn .btn-img {
  width: 23px;
  position: absolute;
  left: 20px;
  top: calc(50% - 11px);
}
body a.btn {
  font-size: 0.9em;
}
body .btn.with-image {
  padding: 10px 20px 10px 50px;
  position: relative;
  background: rgba(51, 51, 51, 0.4);
}
body .btn.moral {
  background: #33A913;
  color: #ffffff;
  border: 1px solid #33A913;
}
body .btn.green {
  background: rgba(51, 169, 19, 0.15);
  color: #444444;
}
body .btn.gdrive {
  background: #4688F4;
  color: #ffffff;
  border: 1px solid #4688F4;
}
body .btn.dropbox {
  background: #0060FF;
  color: #ffffff;
  border: 1px solid #0060FF;
}
body .btn.evernote {
  background: #2EBE60;
  color: #ffffff;
  border: 1px solid #2EBE60;
}
body .btn.gmail {
  background: #D44638;
  color: #ffffff;
  border: 1px solid #D44638;
}
body .btn.full {
  width: 100%;
  margin: 0;
}
body .btn.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
body .btn.bullet span {
  font-size: 0.75em;
  display: block;
}
body .btn[disabled] {
  opacity: 0.5;
  cursor: auto;
}
body .progress-ring-cont {
  position: relative;
}
body .progress-ring-cont .progress-ring {
  transition: 0.35s stroke-dashoffset;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
body .progress-ring-cont .text {
  font-weight: 700;
  color: #33A913;
  font-size: 1.25em;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
body .full-page-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(25, 57, 17, 0.4);
  z-index: 100000;
}
body .btn-link {
  margin-top: 15px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #33A913;
  cursor: pointer;
}
body .btn-link .icon {
  width: 20px;
  margin-right: 10px;
  display: block;
}
body .btn-link.center {
  justify-content: center;
}
body .btn-link.disabled {
  opacity: 0.5;
}
body .s-alert-wrapper .s-alert-box {
  z-index: 2147483647;
  border-radius: 2px;
  padding: 10px 30px 10px 20px;
}
body .s-alert-wrapper .s-alert-box .s-alert-box-inner {
  font-size: 1em;
  font-weight: 400;
}
body .s-alert-wrapper .s-alert-box .s-alert-close {
  top: calc(50% - 10px);
  right: 6px;
}
body .s-alert-wrapper .s-alert-box .s-alert-close::before,
body .s-alert-wrapper .s-alert-box .s-alert-close::after {
  width: 1px;
  height: 80%;
}
body .s-alert-wrapper .s-alert-box.s-alert-success {
  background: #33A913;
}
body .nanobar {
  width: 0;
  height: 3px;
  animation: nanobarrun 1s linear infinite;
  position: fixed;
  top: 0;
  left: 0;
  background: #33A913;
  z-index: 2147483647;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  opacity: 0;
  display: none;
}
body .nanobar.running {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: 1;
  display: block;
}
body .image-select-cont {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
}
body .image-select-cont .image-select {
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 10px;
}
body .tab-container .tab-heading {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ECECEC;
  margin-bottom: 10px;
}
body .tab-container .tab-heading .tabs {
  opacity: 0.7;
  font-weight: 800;
  margin-right: 15px;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  margin-bottom: -1px;
  cursor: pointer;
}
body .tab-container .tab-heading .tabs:hover {
  opacity: 0.8;
}
body .tab-container .tab-heading .tabs.active {
  color: #33A913;
  opacity: 1;
  border-bottom: 3px solid #33A913;
}
body .tab-container .tab-heading .tabs:last-child {
  margin-right: 0;
}
body .logo {
  max-width: 180px;
}
body .logo .company-logo .logo {
  height: 20px;
  display: block;
}
body .react-js-cron .react-js-cron-field > span {
  font-weight: 500;
  font-size: 1em;
  margin-left: 10px;
}
body .react-js-cron .react-js-cron-field .ant-select {
  margin-left: 10px;
}
body .react-js-cron .react-js-cron-field .ant-select .ant-select-selector {
  padding: 0 10px;
  border: 1px solid #ECECEC;
  font-family: 'Roboto', sans-serif;
  border-radius: 2px;
  box-shadow: none;
  height: 35px;
}
body .react-js-cron .react-js-cron-field .ant-select:hover .ant-select-selector,
body .react-js-cron .react-js-cron-field .ant-select.ant-select-focused .ant-select-selector {
  border: 1px solid #A8A8A8 !important;
  box-shadow: none !important;
}
body .react-js-cron .ant-btn {
  border-radius: 2px;
  height: 35px;
  border: 0;
}
body .ant-select-dropdown {
  z-index: 100001;
  padding: 0;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #ECECEC;
  margin-top: -10px;
}
body .ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item {
  border-radius: 0;
}
body .ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item:hover,
body .ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item:focus {
  background-color: rgba(51, 169, 19, 0.15);
}
body .ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option-selected {
  background-color: rgba(51, 169, 19, 0.25);
}
body .ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: #444444;
  font-weight: 500;
}
body .ant-select-dropdown.react-js-cron-select-dropdown-period .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item,
body .ant-select-dropdown.react-js-cron-select-dropdown-week-days .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item {
  margin-bottom: 3px;
}
body .ant-select-dropdown.react-js-cron-select-dropdown-period .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item:last-child,
body .ant-select-dropdown.react-js-cron-select-dropdown-week-days .rc-virtual-list .rc-virtual-list-holder-inner .ant-select-item:last-child {
  margin-bottom: 0;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 -10px;
}
.flex-container .flexbox {
  margin: 0 10px;
  flex-grow: 1;
  align-self: auto;
}
.flex-container .flexbox.full {
  width: calc(100% - 20px);
}
.flex-container .flexbox.half {
  width: calc(50% - 20px);
}
.flex-container .flexbox.one-third {
  width: calc(33.333% - 20px);
}
.flex-container .flexbox.two-third {
  width: calc(66.667% - 20px);
}
.flex-container .flexbox.three-fourth {
  width: calc(75% - 20px);
}
.flex-container .flexbox.one-fourth {
  width: calc(25% - 20px);
}
.flex-container.column {
  flex-direction: column;
}
.flex-container.no-margin {
  margin: 0;
}
.flex-container.flex-start {
  justify-content: flex-start;
}
.flex-container.flex-end {
  justify-content: flex-end;
}
.flex-container.full-height {
  height: 100%;
}
.flex-container.wrap {
  flex-wrap: wrap;
}
.vertical-middle {
  align-items: center;
}
.vertical-bottom {
  align-items: flex-end;
}
.flex-container.author {
  margin-top: 5px;
}
.flex-container.main {
  max-width: 980px;
}
.loader {
  border: 2px solid #A8A8A8;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes nanobarrun {
  0% {
    width: 0%;
  }
  5% {
    width: 0%;
  }
  100% {
    width: 110%;
  }
}
@keyframes nanobarrun {
  0% {
    width: 0%;
  }
  5% {
    width: 0%;
  }
  100% {
    width: 110%;
  }
}
@-webkit-keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
