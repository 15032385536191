/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
body .side-nav {
  z-index: 999;
  width: 240px;
  border-right: 1px solid #ECECEC;
  padding: 55px 0;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
body .side-nav ul {
  margin-top: 35px;
}
body .side-nav ul li {
  margin-right: 0;
  text-align: left;
  padding: 5px 0 5px 30px;
  margin-bottom: 10px;
  border-right: 4px solid #444444;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
body .side-nav ul li .icon {
  width: 30px;
  min-width: 30px;
}
body .side-nav ul li .icon img {
  width: 30px;
  display: block;
}
body .side-nav ul li .text {
  width: calc(100% - 35px);
}
body .side-nav ul li .text span {
  font-weight: 700;
  color: #444444;
  font-size: 0.95em;
}
body .side-nav ul li:hover {
  background: rgba(51, 169, 19, 0.15);
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -ms-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
body .side-nav ul li.active {
  border-right: 4px solid #33A913;
}
body .side-nav ul li.active .text span {
  color: #33A913;
}
body .side-nav ul li.drafts .icon img {
  width: 25px;
}
body .side-nav ul li.images,
body .side-nav ul li.paragraph {
  padding-left: 60px;
}
