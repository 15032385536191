/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
body header {
  background-color: #ffffff;
  height: 55px;
  border-bottom: 1px solid #ECECEC;
  padding: 0 35px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
body header .user .user-details {
  justify-content: flex-end;
  display: flex;
  position: relative;
}
body header .user .user-details .details-holder {
  cursor: pointer;
}
body header .user .user-details .details-holder .dot {
  background: #33A913;
  border-radius: 50%;
  font-weight: 500;
  height: 35px;
  width: 35px;
  padding-top: 5px;
  font-size: 1.28em;
  color: #ffffff;
  display: block;
  cursor: pointer;
}
body header .user .user-details .details-holder .name-email-holder {
  margin-left: 0;
  line-height: 1;
}
body header .user .user-details .details-holder .name-email-holder .name {
  font-weight: 500;
  white-space: nowrap;
  font-size: 1em;
  margin-bottom: 3px;
}
body header .user .user-details .details-holder .name-email-holder .email {
  font-size: 0.85em;
  font-weight: 400;
  color: #A8A8A8;
}
body header .user .user-details .details-holder .dot {
  cursor: default;
}
body header .user .user-details .dropdown-menu {
  position: absolute;
  background: #ffffff;
  top: 100%;
  z-index: 1000;
  border: 1px solid #ECECEC;
  min-width: 200px;
  right: 0;
  margin-top: 7px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 10px 0;
}
body header .user .user-details .dropdown-menu li a {
  padding: 5px 15px;
  background: transparent;
  display: block;
  clear: both;
  color: #33A913;
  line-height: 1.42857143;
  font-size: 1em;
  font-weight: 500;
  white-space: nowrap;
}
body header .user .user-details .dropdown-menu li a:hover {
  background: rgba(51, 169, 19, 0.15);
}
body header .user .user-details .dropdown-menu li:last-child {
  border-bottom: 0;
}
body header .user .user-details .dropdown-menu:before {
  position: absolute;
  top: -7px;
  left: 9px;
  right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ECECEC;
  border-left: 7px solid transparent;
  content: '';
}
body header .user .user-details .dropdown-menu:after {
  position: absolute;
  top: -6px;
  left: 9px;
  right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  border-left: 7px solid transparent;
  content: '';
}
body header .user .user-details.open .dropdown-menu {
  visibility: visible;
  opacity: 1;
}
