/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
.dashboard-container {
  margin: 55px 0 0 240px;
  padding: 35px;
}
.dashboard-container .main-container {
  max-width: 970px;
  margin: auto;
}
.dashboard-container .main-container .heading {
  font-weight: 700;
  font-size: 1.28em;
  color: #444444;
}
.dashboard-container .main-container .sub-heading {
  color: #969595;
}
.dashboard-container .main-container .heading + .sub-heading {
  margin-top: 5px;
}
.dashboard-container .loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(51, 169, 19, 0.15);
  color: #33A913;
  z-index: 10;
}
.dashboard-container .block-container {
  margin-bottom: 20px;
}
.dashboard-container .block-container .block {
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
}
.dashboard-container .full-page-overlay .modal-body {
  background: #ffffff;
  border: 1px solid #ECECEC;
  border-radius: 2px;
  padding: 30px;
}
.dashboard-container .full-page-overlay .modal-body .heading {
  color: #444444;
  font-weight: 500;
  font-size: 1.142em;
  margin-bottom: 15px;
  line-height: 1;
}
.dashboard-container .full-page-overlay .modal-body .sub-heading {
  color: #969595;
  margin-bottom: 5px;
  font-size: 0.9em;
}
.dashboard-container .full-page-overlay .modal-body .sub-heading span {
  color: #444444;
  font-weight: 500;
}
.dashboard-container .full-page-overlay .modal-body .close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  z-index: 100;
}
.dashboard-container .full-page-overlay .modal-body .close img {
  display: block;
  width: 100%;
}
.dashboard-container .full-page-overlay .modal-body .desc {
  font-weight: 100;
  font-size: 0.785em;
  color: #969595;
  margin-bottom: 15px;
  word-break: break-word;
}
.dashboard-container .full-page-overlay .modal-body .desc a,
.dashboard-container .full-page-overlay .modal-body .desc a:hover,
.dashboard-container .full-page-overlay .modal-body .desc a:focus,
.dashboard-container .full-page-overlay .modal-body .desc .green {
  color: #33A913;
  text-decoration: none;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area {
  display: block;
  cursor: pointer;
  background: #F9F8FD;
  border: 1px dashed #ECECEC;
  border-radius: 2px;
  height: 160px;
  margin-bottom: 15px;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area .flex-container,
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area .flexbox {
  margin: 0;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area img {
  display: block;
  width: 70px;
  margin: 0 auto 3px auto;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area .text {
  display: block;
  color: #878585;
  font-size: 0.928em;
  font-weight: 500;
  opacity: 0.8;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area .input {
  display: none;
}
.dashboard-container .full-page-overlay .modal-body #drop-area .upload-area .progress-ring-cont {
  margin: auto;
}
.dashboard-container .full-page-overlay .modal-body #drop-area.highlight .upload-area {
  background-size: 30px 30px;
  background-image: linear-gradient(-45deg, #ECECEC 25%, transparent 25%, transparent 50%, #ECECEC 50%, #ECECEC 75%, transparent 75%, transparent);
  animation: stripes 2s linear infinite;
}
.dashboard-container .full-page-overlay .modal-body .or-separator,
.dashboard-container .full-page-overlay .modal-body .line-separator {
  height: 1px;
  background-color: #ECECEC;
  margin: 15px 0;
  position: relative;
}
.dashboard-container .full-page-overlay .modal-body .or-separator:after {
  content: 'OR';
  width: 16px;
  height: 16px;
  position: absolute;
  font-weight: 500;
  font-size: 0.785em;
  left: calc(50% - 13px);
  top: calc(50% - 13px);
  background-color: #ffffff;
  padding: 5px;
}
.dashboard-container .full-page-overlay .modal-body .button-holder .btn {
  margin-bottom: 10px;
}
.dashboard-container .full-page-overlay .modal-body .button-holder .btn:last-child {
  border-bottom: 0;
}
.dashboard-container .full-page-overlay .modal-body .btn-desc {
  font-size: 0.857em;
  color: #969595;
  line-height: 1.2;
}
.dashboard-container .full-page-overlay .template-uploader {
  width: 400px;
  margin: 20px auto;
  display: block;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector {
  padding: 10px 10px 10px 5px;
  border-radius: 2px;
  border: 1px solid #ECECEC;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector .icon {
  width: 60px;
  display: block;
  margin-right: 5px;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector .text .title {
  font-weight: 500;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}
.dashboard-container .full-page-overlay .template-uploader .file-folder-list {
  overflow-y: auto;
  height: calc(100vh - 150px);
}
.dashboard-container .full-page-overlay .template-uploader .bottom-btn-container {
  border-top: 1px solid #ECECEC;
  padding-top: 15px;
  text-align: right;
}
.dashboard-container .full-page-overlay .template-uploader .bottom-btn-container .btn {
  margin-right: 0;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector.below-space {
  margin-bottom: 10px;
  cursor: initial;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector.below-space .icon {
  width: 50px;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector.below-space .btn-cont {
  margin: 0;
  text-align: right;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector.below-space .btn-cont .btn:last-child {
  margin-right: 0;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector.below-space .btn {
  margin-left: auto;
}
.dashboard-container .full-page-overlay .template-uploader .option-selector:hover {
  border: 1px solid #969595;
}
.dashboard-container .full-page-overlay .template-uploader.cloud-storage {
  width: 550px;
}
.dashboard-container .full-page-overlay .big-modal {
  width: calc(100% - 200px);
  margin: auto;
  display: block;
}
.dashboard-container .full-page-overlay .big-modal .modal-body {
  padding: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .flex-container {
  margin: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step {
  width: 300px;
  background: #F9F8FD;
  margin: 0;
  padding: 35px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps {
  margin: 0 -35px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li {
  padding: 15px 35px;
  opacity: 0.65;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li .step-heading {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 3px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li:after {
  content: '';
  position: absolute;
  right: 0;
  width: 5px;
  background: #969595;
  top: 15px;
  height: 52px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li.active {
  background: rgba(51, 169, 19, 0.15);
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li.done,
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li.active {
  opacity: 1;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li.active:after,
.dashboard-container .full-page-overlay .big-modal .modal-body .step .steps li.done:after {
  background: #33A913;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action {
  width: calc(100% - 300px);
  margin: 0;
  padding: 40px 40px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .main-heading {
  font-weight: 700;
  font-size: 1.285em;
  line-height: 1.2;
  margin-bottom: 5px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .more-desc {
  color: #969595;
  line-height: 1.2;
  margin-bottom: 15px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  overflow-y: auto;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ECECEC;
  border-radius: 2px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td {
  box-sizing: border-box;
  text-align: left;
  flex: 1 0 10em;
  border-bottom: 1px solid #ECECEC;
  padding: 5px 5px 5px 10px;
  border-right: 1px solid #ECECEC;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th .icon,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td .icon {
  display: block;
  width: 19px;
  margin: 6px auto;
  cursor: pointer;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th .icon.plus,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td .icon.plus {
  width: 25px;
  margin: 4px auto;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th .icon.disabled,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td .icon.disabled {
  cursor: auto;
  opacity: 0.5;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td .custom-input {
  margin: 6px auto 7px;
  padding: 0;
  width: 18px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td .input-field {
  border: 0;
  height: 33px;
  padding: 5px 10px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table thead,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table tbody,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table tfoot {
  display: flex;
  flex-direction: column;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table tr {
  display: flex;
  flex: 1 0;
  align-items: center;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th:last-child,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td:last-child {
  border-right: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table th:first-child,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table td:first-child {
  max-width: 35px;
  padding: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table tbody tr:last-child td {
  border-bottom: 0;
  padding: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map {
  border: 0;
  overflow: visible;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td:first-child {
  min-width: 30%;
  max-width: 30%;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td:last-child {
  overflow: visible;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td {
  border: 0;
  padding: 0 0 5px 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td .input-select div[class*="-control"] {
  border-width: 0 0 1px 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td .input-select div[class*="-control"] > div:first-child {
  padding: 0 8px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.map tr td .input-select div[class*="-control"] > div:last-child > span + div {
  padding: 6px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.has-image {
  overflow: visible;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.has-image tbody tr td {
  overflow: visible;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container table.table.has-image tbody tr td .input-select div[class*="-control"] {
  border: 0;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container.auto-height {
  max-height: auto;
  min-height: auto;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .footer {
  border-top: 1px solid #ECECEC;
  padding-top: 15px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .footer .btn:last-child {
  margin-right: 0;
}
.dashboard-container .full-page-overlay .big-modal .template-uploader .modal-body {
  padding: 30px;
}
.dashboard-container .left {
  padding-right: 5px;
}
.dashboard-container .right {
  padding-left: 20px;
}
.dashboard-container .radio-selection > .flexbox {
  margin: 0;
}
.dashboard-container .buttons .actions {
  position: absolute;
  top: calc(100% + 6px);
  z-index: 100;
  background: #ffffff;
  padding: 5px 0;
  border: 1px solid #ECECEC;
  right: 0;
  font-size: 1em;
  visibility: hidden;
  opacity: 0;
  border-radius: 2px;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
.dashboard-container .buttons .actions li {
  padding: 7px 15px;
  text-align: left;
  color: #33A913;
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
}
.dashboard-container .buttons .actions li:hover {
  background-color: rgba(51, 169, 19, 0.15);
}
.dashboard-container .buttons .actions:after {
  position: absolute;
  top: -6px;
  right: 15px;
  left: auto;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  border-left: 7px solid transparent;
  content: '';
}
.dashboard-container .buttons .actions:before {
  position: absolute;
  top: -7px;
  right: 15px;
  left: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ECECEC;
  border-left: 7px solid transparent;
  content: '';
}
.dashboard-container .buttons.open .actions {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
.dashboard-container .data-row {
  padding: 10px 0;
  border-bottom: 1px solid rgba(135, 133, 133, 0.47);
}
.dashboard-container .data-row .checkbox {
  width: 30px;
}
.dashboard-container .data-row .checkbox .form-group,
.dashboard-container .data-row .checkbox .custom-input {
  margin: 0;
}
.dashboard-container .data-row .icon-name {
  width: calc(100% - 310px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.dashboard-container .data-row .icon-name .file-icon .icon {
  width: 50px;
  display: block;
}
.dashboard-container .data-row .icon-name .details {
  padding-left: 10px;
}
.dashboard-container .data-row .icon-name .details .name {
  font-size: 1em;
  color: #444444;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 230px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-container .data-row .icon-name .details a.name:hover {
  color: #33A913;
  text-decoration: underline;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-container .data-row .icon-name .details .date-size {
  font-size: 0.85em;
  color: #969595;
}
.dashboard-container .data-row .icon-name .details .date-size .separator {
  padding: 0 5px;
}
.dashboard-container .data-row .icon-name.automation {
  width: calc(100% - 420px);
}
.dashboard-container .data-row .icon-name.automation .details {
  width: 100%;
}
.dashboard-container .data-row .icon-name.automation .details .name,
.dashboard-container .data-row .icon-name.automation .details .date-size {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.dashboard-container .data-row .icon-name.automation + .cronvalue {
  font-size: 0.9em;
}
.dashboard-container .data-row .buttons {
  width: 280px;
  display: flex;
  justify-content: flex-end;
}
.dashboard-container .data-row .buttons .btn {
  margin: 0 9px;
}
.dashboard-container .data-row .buttons .btn:first-child {
  margin-left: 0;
}
.dashboard-container .data-row .buttons .btn.bullet {
  margin-right: 0;
}
.dashboard-container .data-row .buttons.auto-width {
  width: auto;
}
.dashboard-container .data-row.folder .icon-name .details .name {
  text-transform: capitalize;
}
.dashboard-container .data-row.grid.folder .icon-name .details .name {
  margin-bottom: 21px;
}
.dashboard-container .grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dashboard-container .grid-container .data-row.grid {
  padding: 13px;
  width: calc(33.33% - 10px);
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ECECEC;
  margin-top: 15px;
  margin-right: 15px;
}
.dashboard-container .grid-container .data-row.grid .header .icon-name {
  margin-bottom: 5px;
  align-items: flex-start;
}
.dashboard-container .grid-container .data-row.grid .header .icon-name .file-icon {
  position: relative;
  left: -7px;
}
.dashboard-container .grid-container .data-row.grid .header .form-group {
  margin: 0;
}
.dashboard-container .grid-container .data-row.grid .header .form-group .custom-input {
  margin: 0;
  padding-left: 18px;
}
.dashboard-container .grid-container .data-row.grid .header .buttons .btn {
  padding: 3px 10px;
  height: 18px;
  margin-right: 5px;
}
.dashboard-container .grid-container .data-row.grid .header .buttons .btn span {
  font-size: 0.65em;
}
.dashboard-container .grid-container .data-row.grid .icon-name {
  width: 100%;
  margin-bottom: 10px;
}
.dashboard-container .grid-container .data-row.grid .icon-name .details {
  width: 100%;
  padding: 0;
}
.dashboard-container .grid-container .data-row.grid .icon-name .details .date-size {
  font-size: 0.8em;
}
.dashboard-container .grid-container .data-row.grid .button-container {
  display: flex;
  justify-content: space-between;
}
.dashboard-container .grid-container .data-row.grid .button-container .btn {
  margin: 0;
}
.dashboard-container .grid-container .data-row.grid .header.draft .icon-name {
  align-items: center;
}
.dashboard-container .grid-container .data-row.grid .header.draft .icon-name .details .name {
  max-width: 120px;
}
.dashboard-container .grid-container .data-row.grid .header.draft .buttons.draft {
  position: absolute;
  width: auto;
  top: -4px;
  right: -6px;
}
.dashboard-container .grid-container .data-row.grid:nth-child(3n) {
  margin-right: 0;
}
.dashboard-container .row-heading {
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid #878585;
}
.dashboard-container .row-heading .select-all {
  width: 40px;
}
.dashboard-container .row-heading .select-all .form-group,
.dashboard-container .row-heading .select-all .custom-input {
  margin: 0;
}
.dashboard-container .row-heading .name {
  width: calc(100% - 220px);
}
.dashboard-container .row-heading .name .home {
  cursor: pointer;
  color: #33A913;
}
.dashboard-container .row-heading .name .separator {
  margin: 0 5px;
}
.dashboard-container .row-heading .view-type {
  width: 180px;
}
.dashboard-container .row-heading .view-type .view {
  margin: 0;
  justify-content: flex-end;
}
.dashboard-container .row-heading .view-type .view .active {
  color: #33A913;
}
.dashboard-container .row-heading .view-type .view .list,
.dashboard-container .row-heading .view-type .view .grid {
  padding: 0 10px;
  margin: 0;
  display: flex;
  align-items: center;
  width: calc(50% - 20px);
  cursor: pointer;
}
.dashboard-container .row-heading .view-type .view .list img,
.dashboard-container .row-heading .view-type .view .grid img {
  margin-right: 5px;
  width: 20px;
}
.dashboard-container .row-heading .view-type .view .grid img {
  width: 18px;
}
.dashboard-container .row-heading .view-type .view .list {
  border-right: 1px solid #878585;
}
.dashboard-container .logs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(135, 133, 133, 0.47);
  padding: 10px 0;
  gap: 15px;
}
.dashboard-container .logs .log-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #F7F7F7;
  border-radius: 50%;
}
.dashboard-container .logs .log-icon .icon {
  height: 25px;
  width: 25px;
}
.dashboard-container .logs .log-icon .icon.started {
  color: #7f7aff;
}
.dashboard-container .logs .log-icon .icon.requested {
  color: #ff7f48;
}
.dashboard-container .logs .log-icon .icon.success {
  color: #33A913;
}
.dashboard-container .logs .log-icon .icon.failed {
  color: #D44638;
}
.dashboard-container .logs .log-type {
  padding-right: 10px;
  max-width: 260px;
}
.dashboard-container .logs .log-type span.name {
  display: block;
  font-weight: 600;
  color: #444444;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.dashboard-container .logs .log-type .type {
  font-weight: 500;
  font-size: 0.8em;
  word-wrap: break-word;
  white-space: initial;
}
.dashboard-container .logs .log-type span.time {
  font-size: 0.85em;
  color: #969595;
}
.dashboard-container .logs .log-type.no-padding {
  padding: 0;
}
.dashboard-container .logs .log-desc {
  width: calc(100% - 370px);
}
.dashboard-container .logs .log-desc span {
  font-size: 0.9em;
  padding-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.dashboard-container .logs .button {
  width: 110px;
  max-width: 110px;
  text-align: right;
}
.dashboard-container .logs .button .btn {
  margin: 0;
}
.dashboard-container .logs:last-child {
  border: 0;
}
.dashboard-container .max-width {
  max-width: 100%;
}
.dashboard-container .meta-container {
  padding: 15px;
  border: 1px solid #ECECEC;
  border-radius: 2px;
}
.dashboard-container .meta-container .meta-heading {
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 5px;
}
.dashboard-container .meta-container label[for],
.dashboard-container .meta-container .custom-input[data-type="radio"] {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 3px;
}
.dashboard-container .meta-container .custom-input[data-type="radio"] {
  display: flex;
  align-items: center;
}
.dashboard-container .meta-container .custom-input {
  margin: 0;
}
.dashboard-container .meta-container label[for="option"] {
  font-size: 0.93em;
  font-weight: 500;
}
.dashboard-container .meta-container .required {
  padding-left: 15px;
}
.dashboard-container .meta-container .required .custom-input {
  font-size: 0.9em;
}
.dashboard-container .meta-container .input-select div[class*="-control"] {
  font-size: 0.85em;
}
.dashboard-container .meta-container .input-select div[class*="-indicatorContainer"] {
  padding: 5px;
}
.dashboard-container .meta-container .input-select div[class*="-menu"] div[class*="-MenuList"] div[class*="-option"] {
  font-size: 0.85em;
}
.dashboard-container .meta-container .input-field {
  height: 32px;
  font-size: 0.85em;
}
.dashboard-container .meta-container .help-text,
.dashboard-container .meta-container .options {
  padding-top: 10px;
}
.dashboard-container .meta-container .option-generator {
  margin: 0 -10px;
}
.dashboard-container .meta-container .option-generator .custom-input[data-type="checkbox"] {
  margin-top: 25px;
}
.dashboard-container .meta-container .option-button {
  padding-top: 10px;
}
.dashboard-container .meta-container .option-button .btn {
  margin: 0;
  font-size: 0.9em;
  height: 30px;
  padding: 8px 15px;
}
.dashboard-container .meta-container .form-group {
  margin-bottom: 10px;
}
.dashboard-container .meta-container .footer {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.dashboard-container .meta-container .footer .btn {
  margin: 0;
}
.dashboard-container .meta-container.error {
  border: 1px solid #D44638;
}
.dashboard-container .group-creation .options {
  border: 1px solid #ECECEC;
  padding: 10px 10px 0;
  border-radius: 2px;
}
.dashboard-container .group-creation .options .input.flexbox {
  margin-bottom: 10px;
}
.dashboard-container .group-creation .groups {
  border: 1px solid #ECECEC;
  padding: 10px;
  border-radius: 2px;
}
.dashboard-container .group-creation .groups .form-group {
  margin-bottom: 10px;
}
.dashboard-container .group-creation .groups .form-group label[for] {
  font-size: 0.95em;
}
.dashboard-container .group-creation .groups .form-group .input-field {
  padding: 6px 10px;
  height: 32px;
  font-size: 0.9em;
}
.dashboard-container .group-creation .groups .btn {
  margin: 0;
  padding: 8px 15px;
  font-size: 0.9em;
  height: 32px;
}
.dashboard-container .group-creation .group {
  margin-bottom: 15px;
  border: 1px solid #ECECEC;
  padding: 10px;
  border-radius: 2px;
}
.dashboard-container .group-creation .group .group-name {
  font-weight: 500;
  font-size: 0.9em;
  display: block;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.dashboard-container .group-creation .group .group-name .flex-container {
  margin: 0 -10px;
}
.dashboard-container .group-creation .group .group-name .flex-container .buttons {
  max-width: 150px;
  justify-content: flex-end;
  display: flex;
}
.dashboard-container .group-creation .group .group-name .flex-container .buttons .btn.small {
  padding: 5px 15px;
  height: 25px;
  margin-left: 10px;
}
.dashboard-container .group-creation .group .options {
  position: relative;
}
.dashboard-container .group-creation .group .options > div:empty:after {
  content: 'Drag fields from left side';
  position: absolute;
  opacity: 0.6;
  top: 10px;
  left: 10px;
}
.dashboard-container .group-creation .group .options .custom-input {
  margin-bottom: 0;
}
.dashboard-container .group-creation .group .options .custom-input.used {
  opacity: 0.5;
  cursor: default;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table th,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table td {
  font-size: 0.9em;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table th .icon,
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table td .icon {
  margin: 4px auto;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table td .custom-input {
  margin: 5px auto 5px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table td .custom-input.normal-text {
  margin: 0 0 0 20px;
  padding-left: 28px;
}
.dashboard-container .full-page-overlay .big-modal .modal-body .action .form-container .meta-container table.table td .input-field {
  height: 32px;
  font-size: 0.95em;
}
.dashboard-container .integration-options {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.dashboard-container .integration-options .options {
  padding: 20px;
  border: 1px solid #ECECEC;
  background: #ffffff;
  border-radius: 2px;
}
.dashboard-container .integration-options .options .icon {
  max-width: 100%;
  min-width: 70px;
  max-height: 70px;
}
.dashboard-container .integration-options .options .title {
  font-size: 1.15em;
  color: #444444;
  font-weight: 700;
}
.dashboard-container .integration-options .options .text {
  font-size: 0.95em;
  color: #A8A8A8;
  margin-bottom: 10px;
}
.dashboard-container .integration-options .options .btn {
  margin-left: 0;
}
.dashboard-container .tab-body.cloud-storage .platform {
  margin-bottom: 10px;
  background: #F9F8FD;
  border: 1px solid #ECECEC;
}
.dashboard-container .tab-body.cloud-storage .platform .icon {
  max-width: 60px;
}
.dashboard-container .tab-body.cloud-storage .platform .icon img {
  display: block;
  margin-left: 10px;
}
.dashboard-container .tab-body.cloud-storage .platform .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .tab-body.cloud-storage .platform .button {
  text-align: right;
}
.dashboard-container .tab-body.cloud-storage .platform.selected {
  background: rgba(51, 169, 19, 0.15);
  border: 1px solid #33A913;
  color: #33A913;
}
.dashboard-container .tab-body.cloud-storage .platform.selected .name span {
  margin-left: 10px;
  color: #444444;
}
.dashboard-container .tab-body.cloud-storage .platform:last-child {
  margin-bottom: 0;
}
.dashboard-container .docusign .group-creation .groups .group.error {
  border: 1px solid #D44638;
}
.dashboard-container .docusign .group-creation .options {
  padding: 0;
  border: 0;
}
.dashboard-container .docusign .group-creation .radio-selection label[for] {
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 3px;
}
.dashboard-container .docusign .group-creation .radio-selection .input-select div[class*="-control"] {
  font-size: 0.85em;
}
.dashboard-container .docusign .group-creation .radio-selection .input-select div[class*="-indicatorContainer"] {
  padding: 5px;
}
.dashboard-container .docusign .group-creation .radio-selection > .flexbox:first-child {
  padding-right: 10px;
}
.dashboard-container .docusign .group-creation .radio-selection > .flexbox:last-child {
  padding-left: 10px;
}
.dashboard-container .searchbar {
  margin-bottom: 20px;
}
.dashboard-container .searchbar .custom-search-bar .search-icon {
  position: absolute;
  left: 10px;
  top: 5px;
  background-image: url("../svg/search.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  z-index: 60;
  background-position: 50%;
  background-size: 70%;
}
.dashboard-container .searchbar .custom-search-bar .input-field {
  height: 40px;
  padding-left: 50px;
}
.dashboard-container .searchbar .custom-search-bar .clear {
  color: #33A913;
  font-weight: 500;
  position: absolute;
  top: 10px;
  z-index: 100;
  right: 15px;
  cursor: pointer;
}
.dashboard-container .searchbar .sort {
  height: 40px;
  background: #ffffff;
  border: 1px solid #ECECEC;
  border-radius: 2px;
  font-size: 0.93em;
}
.dashboard-container .searchbar .sort .click-overlay {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.dashboard-container .searchbar .sort .sortby {
  cursor: pointer;
  padding: 10px 11px 9px 12px;
  position: relative;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dashboard-container .searchbar .sort .sortby .select-text {
  font-weight: 500;
  margin-left: 5px;
}
.dashboard-container .searchbar .sort .sortby .down-arrow {
  background-image: url("../svg/down-arrow.svg");
  position: absolute;
  width: 30px;
  height: 30px;
  background-size: 50%;
  background-position: 50%;
  right: 5px;
  top: 5px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.dashboard-container .searchbar .sort .sortby .sort-list {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: #ffffff;
  border: 1px solid #ECECEC;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -ms-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  padding: 5px 0;
}
.dashboard-container .searchbar .sort .sortby .sort-list li {
  padding: 7px 15px;
  background: transparent;
  display: block;
  clear: both;
  color: #33A913;
  white-space: nowrap;
  cursor: pointer;
}
.dashboard-container .searchbar .sort .sortby .sort-list li label {
  cursor: pointer;
}
.dashboard-container .searchbar .sort .sortby .sort-list li.selected,
.dashboard-container .searchbar .sort .sortby .sort-list li:hover {
  background: rgba(51, 169, 19, 0.15);
}
.dashboard-container .searchbar .sort .sortby.open .sort-list {
  visibility: visible;
  opacity: 1;
}
.dashboard-container .searchbar .sort .sortby.open .down-arrow {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  transition: transform 0.2s ease 0s;
}
.dashboard-container .key-value .key {
  color: #969595;
}
.dashboard-container .key-value .value {
  color: #444444;
}
.dashboard-container .key-value .value.fixed-width {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .key-value.columns {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 20px;
  white-space: nowrap;
  grid-row-gap: 8px;
}
.dashboard-container .key-value:not(.columns) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dashboard-container .key-value:not(.columns) .key {
  padding-right: 12px;
  position: relative;
}
.dashboard-container .key-value:not(.columns) .value {
  margin-right: 36px;
}
.dashboard-container .key-value:not(.columns) .key:after {
  content: ":";
  position: absolute;
  right: 6px;
  top: 0;
}
.dashboard-container .key-value:not(.columns) .value:last-child {
  margin-right: 0;
}
.dashboard-container.dashboard {
  padding: 20px 35px 35px;
}
.dashboard-container.dashboard .block {
  position: relative;
  overflow: hidden;
}
.dashboard-container.dashboard .block .flexbox {
  margin: 0;
}
.dashboard-container.dashboard .block .icon-container {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dashboard-container.dashboard .block .icon-container .icon {
  height: 30px;
  width: 30px;
}
.dashboard-container.dashboard .block .data {
  margin-left: 0;
  width: calc(100% - 90px);
  line-height: 1;
  color: #969595;
}
.dashboard-container.dashboard .block .data .main-number {
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 5px;
  color: #444444;
}
.dashboard-container.dashboard .block .data .description {
  font-weight: 700;
  margin-bottom: 5px;
}
.dashboard-container.dashboard .block .data .last-update {
  font-size: 0.78em;
}
.dashboard-container.dashboard .block.with-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-container.dashboard .template.block {
  background-color: rgba(255, 127, 72, 0.2);
}
.dashboard-container.dashboard .template.block > .icon-container {
  background-color: #ff7f48;
}
.dashboard-container.dashboard .document.block {
  background-color: rgba(127, 122, 255, 0.2);
}
.dashboard-container.dashboard .document.block > .icon-container {
  background-color: #7f7aff;
}
.dashboard-container.dashboard .image.block {
  background-color: rgba(51, 169, 19, 0.2);
}
.dashboard-container.dashboard .image.block > .icon-container {
  background-color: #33A913;
}
.dashboard-container.dashboard .date-range {
  margin-top: 5px;
}
.dashboard-container.dashboard .date-range .react-datepicker-wrapper input {
  max-width: 130px;
  padding: 5px 10px 4px;
  border-radius: 2px;
  outline: 0;
  border: 1px solid #ECECEC;
}
.dashboard-container.dashboard .date-range > .flexbox {
  position: relative;
}
.dashboard-container.dashboard .date-range > .flexbox:first-child:after {
  content: "-";
  position: absolute;
  right: -11px;
  top: 3px;
  color: #969595;
}
.dashboard-container.dashboard .details-block-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-container.dashboard .details-block-container .details-block {
  background-color: #F7F7F7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.dashboard-container.dashboard .details-block-container .details-block .name-role {
  display: flex;
  gap: 10px;
  align-items: center;
}
.dashboard-container.dashboard .details-block-container .details-block .name-role h3 {
  font-weight: 700;
  font-size: 1.28em;
  color: #444444;
  text-transform: capitalize;
}
.dashboard-container.dashboard .details-block-container .details-block .name-role .role {
  display: flex;
  align-items: center;
  gap: 5px;
}
.dashboard-container.dashboard .details-block-container .details-block .name-role .role .role-icon {
  fill: #7f7aff;
  stroke: #7f7aff;
  width: 15px;
  height: auto;
}
.dashboard-container.dashboard .details-block-container .details-block .name-role .role span {
  line-height: 18px;
  font-size: 0.85em;
  color: #969595;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container {
  display: flex;
  gap: 10px;
  margin: 0;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block {
  padding: 10px;
  background-color: #ffffff;
  gap: 10px;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .icon-container {
  height: auto;
  width: auto;
  background-color: transparent;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .icon-container .icon.template {
  fill: #ff7f48;
  stroke: #ff7f48;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .icon-container .icon.document {
  fill: #7f7aff;
  stroke: #7f7aff;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .icon-container .icon.image {
  fill: #33A913;
  stroke: #33A913;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .data .main-number {
  font-size: 1.6em;
}
.dashboard-container.dashboard .details-block-container .details-block .block-container .block .data .description {
  font-size: 0.8em;
  margin: 0;
}
.dashboard-container.template .template-details {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #A8A8A8;
  margin-bottom: 15px;
}
.dashboard-container.template .template-details .icon-container {
  display: flex;
  align-items: center;
}
.dashboard-container.template .template-details .icon-container .icon {
  max-width: 60px;
  height: auto;
}
.dashboard-container.template .template-details .name-size-block {
  display: flex;
  flex-direction: column;
}
.dashboard-container.template .template-details .name-size-block .name {
  font-size: 1.35em;
  color: #444444;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboard-container.template .template-details .name-size-block .date-size {
  color: #969595;
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 0.9em;
}
.dashboard-container.template .timeline-block {
  position: relative;
  padding: 15px 0 0 125px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-container.template .timeline-block > li {
  padding: 10px;
  position: relative;
  border: 1px solid #ECECEC;
  background: #ffffff;
  border-radius: 2px;
}
.dashboard-container.template .timeline-block > li .dot {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #F7F7F7;
  border: 2px solid #33A913;
  position: absolute;
  left: -28px;
  top: -1.5px;
}
.dashboard-container.template .timeline-block > li .time {
  position: absolute;
  font-size: 0.85em;
  font-weight: 500;
  left: -125px;
  top: 0;
  color: #969595;
}
.dashboard-container.template .timeline-block > li .title {
  font-size: 1em;
  color: #444444;
  font-weight: 500;
}
.dashboard-container.template .timeline-block > li .buttons {
  position: absolute;
  width: auto;
  top: 4px;
  right: 4px;
}
.dashboard-container.template .timeline-block > li .buttons .btn.bullet {
  margin: 0;
  padding: 3px 10px;
  height: 18px;
}
.dashboard-container.template .timeline-block > li.current-version {
  background: rgba(51, 169, 19, 0.15);
}
.dashboard-container.template .timeline-block > li.current-version .dot {
  background: #33A913;
}
.dashboard-container.template .timeline-block > li:last-child:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  border: 1px solid #F7F7F7;
  top: 14.5px;
  bottom: -1px;
  left: -21px;
}
.dashboard-container.template .timeline-block:before {
  content: "";
  display: block;
  width: 0;
  border: 1px solid #33A913;
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 105px;
}
.dashboard-container.drafts .grid-container .data-row.grid {
  width: calc(24.87% - 10px);
}
.dashboard-container.drafts .grid-container .data-row.grid .icon-name .details .name {
  margin: 0;
}
.dashboard-container.drafts .grid-container .data-row.grid:nth-child(3n) {
  margin-right: 15px;
}
.dashboard-container.drafts .grid-container .data-row.grid:nth-child(4n) {
  margin-right: 0;
}
.dashboard-container.drafts .grid-container .data-row.grid.folder .header .icon-name {
  height: 40px;
}
.dashboard-container.drafts .grid-container .data-row.grid.folder .header .icon-name .file-icon {
  left: -3px;
  top: -5px;
}
.dashboard-container.drafts .data-row.list .checkbox {
  display: none;
}
.dashboard-container.profile {
  margin-left: 0;
}
.dashboard-container.profile .main-container {
  max-width: 720px;
}
.dashboard-container.profile .main-container .image-cont {
  max-width: 200px;
}
.dashboard-container.profile .main-container .image-cont .image {
  width: 190px;
  border-radius: 2px;
}
.dashboard-container.profile .main-container .details-cont .name {
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.2;
  margin-bottom: 10px;
}
.dashboard-container.profile .main-container .details-cont .form-group {
  margin-bottom: 15px;
}
.dashboard-container.profile .main-container .details-cont .form-group label[for] {
  margin-bottom: 2px;
}
.dashboard-container.profile .main-container .details-cont .form-group p {
  color: #878585;
  font-size: 0.95em;
}
.dashboard-container.profile .main-container .details-cont .btn {
  margin: 0;
}
.dashboard-container.profile .main-container .mail-setup {
  margin-top: 25px;
}
.dashboard-container.profile .main-container .button-holder .btn:first-child {
  margin-left: 0;
}
.dashboard-container.integrations .main-container .body .body-section {
  padding-bottom: 20px;
}
.dashboard-container.integrations .main-container .body .body-section:last-child {
  padding-bottom: 0;
}
