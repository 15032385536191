/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
body .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .loading-container.vertical {
  flex-direction: column;
  gap: 10px;
}
body .loading {
  font-size: 1.07692308em;
  color: #969595;
  font-weight: 400;
}
body .loading img.loading {
  height: 20px;
  margin-bottom: -8px;
  margin-right: 5px;
  transform: scale(4);
}
body .public-form .logo {
  margin: auto;
}
body .public-form .loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
body .public-form .loading-screen .logo-text {
  font-size: 12px;
  color: #A8A8A8;
  margin: 5px 0 10px;
}
body .public-form .loading-screen .logo img.logo {
  height: 25px;
}
body .public-form .main-container {
  max-width: 970px;
  margin: 55px auto;
  padding: 30px 0;
}
body .public-form .main-container .form-section {
  background: #ffffff;
  border: 1px solid #ECECEC;
  padding: 20px;
}
body .public-form .main-container .form-section .form-group .has-helptext label[for] {
  margin-bottom: 0;
}
body .public-form .main-container .form-section .form-group .has-helptext .help-text {
  font-size: 0.85em;
  margin-bottom: 5px;
  color: #878585;
}
body .public-form .main-container .form-section .form-group .has-helptext .help-text:first-letter {
  text-transform: uppercase;
}
body .public-form .main-container .form-section .form-group .input-field.error {
  border-color: #D44638;
}
body .public-form .main-container .form-section .form-group table.table {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ECECEC;
  border-radius: 2px;
}
body .public-form .main-container .form-section .form-group table.table tr {
  display: flex;
  flex: 1 0;
  align-items: center;
}
body .public-form .main-container .form-section .form-group table.table td,
body .public-form .main-container .form-section .form-group table.table th {
  box-sizing: border-box;
  text-align: left;
  flex: 1 0 10em;
  border-bottom: 1px solid #ECECEC;
  padding: 5px 5px 5px 10px;
  border-right: 1px solid #ECECEC;
}
body .public-form .main-container .form-section .form-group table.table td .icon,
body .public-form .main-container .form-section .form-group table.table th .icon {
  display: block;
  width: 19px;
  margin: 6px auto;
  cursor: pointer;
}
body .public-form .main-container .form-section .form-group table.table td .icon.disabled,
body .public-form .main-container .form-section .form-group table.table th .icon.disabled {
  cursor: auto;
  opacity: 0.5;
}
body .public-form .main-container .form-section .form-group table.table th:first-child,
body .public-form .main-container .form-section .form-group table.table td:first-child {
  max-width: 35px;
  padding: 0;
}
body .public-form .main-container .form-section .form-group table.table td:last-child,
body .public-form .main-container .form-section .form-group table.table th:last-child {
  border-right: 0;
}
body .public-form .main-container .form-section .form-group table.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .public-form .main-container .form-section .form-group table.table td .input-field {
  border: 0;
  height: 33px;
  padding: 5px 10px;
}
body .public-form .main-container .form-section .form-group table.table td .icon.plus {
  width: 25px;
  margin: 4px auto;
}
body .public-form .main-container .form-section .form-group table.table td .custom-input {
  margin: 6px auto 7px;
  padding: 0;
  width: 18px;
}
body .public-form .main-container .form-section .form-group table.table tbody tr:last-child td {
  border-bottom: 0;
  padding: 0;
}
body .public-form .main-container .form-section .form-group table.table,
body .public-form .main-container .form-section .form-group table.table tbody,
body .public-form .main-container .form-section .form-group table.table tfoot,
body .public-form .main-container .form-section .form-group table.table thead {
  display: flex;
  flex-direction: column;
}
body .public-form .main-container .footer {
  border-top: 1px solid #ECECEC;
  padding-top: 15px;
  text-align: right;
}
body .public-form .main-container .footer .btn:last-child {
  margin-right: 0;
}
body .react-datepicker-popper {
  z-index: 100;
  display: flex;
}
body .react-datepicker-popper > div {
  display: flex;
}
body .react-datepicker-popper .react-datepicker {
  font-family: 'Roboto', sans-serif;
  border-radius: 2px;
  border: 1px solid #ECECEC;
  font-size: 14px;
  color: #444444;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
  display: none;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
  border: 0;
  border-bottom: 1px solid #444444;
  border-right: 1px solid #444444;
  top: 15px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
  transform: rotate(135deg);
  left: 20px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
  transform: rotate(-45deg);
  right: 20px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header {
  padding-top: 10px;
  background: #ffffff;
  border-bottom: 1px solid #ECECEC;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__day-names {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  font-size: 0.8em;
  margin: 0;
  line-height: 18px;
  color: #969595;
  font-weight: 500;
  width: 32px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown {
  font-size: 1em;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
  text-align: left;
  border: 1px solid #ECECEC;
  padding: 2px 10px;
  font-size: 0.9em;
  border-radius: 2px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-read-view--down-arrow,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__month-read-view--down-arrow,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__year-read-view--down-arrow,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-read-view--down-arrow {
  background: none;
  width: 8px;
  height: 8px;
  border: 0;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
  transform: rotate(135deg);
  top: 3px;
  margin-bottom: 0;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-read-view--down-arrow:before,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__month-read-view--down-arrow:before,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__year-read-view--down-arrow:before,
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-read-view--down-arrow:before {
  display: none;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container {
  margin: 5px 5px 0 10px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown {
  background: #ffffff;
  border: solid 1px #ECECEC;
  border-radius: 2px;
  left: 10px;
  top: 37px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option {
  padding: 5px 15px;
  text-align: left;
  font-size: 0.95em;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option .react-datepicker__month-option--selected {
  display: none;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option:hover {
  background-color: #F7F7F7;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option.react-datepicker__month-option--selected_month {
  font-weight: 500;
  background-color: #F7F7F7;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option:first-of-type {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container .react-datepicker__month-dropdown .react-datepicker__month-option:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
  margin: 5px 10px 0 5px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown {
  background: #ffffff;
  border: solid 1px #ECECEC;
  border-radius: 2px;
  left: auto;
  right: 15px;
  top: 37px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option {
  padding: 5px 15px;
  text-align: left;
  font-size: 0.95em;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option .react-datepicker__year-option--selected {
  display: none;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years-upcoming {
  transform: rotate(-135deg);
  top: 3px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years-previous {
  transform: rotate(45deg);
  top: -3px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option:hover {
  background-color: #F7F7F7;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option.react-datepicker__year-option--selected_year {
  font-weight: 500;
  background-color: #F7F7F7;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option:first-of-type {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown .react-datepicker__year-option:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month {
  margin: 0;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__week {
  justify-content: space-between;
  display: flex;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  margin: 0;
  width: 32px;
  padding: 2px 0px;
  border-top: 1px solid #ECECEC;
  border-left: 1px solid #ECECEC;
  border-radius: 0;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
  background-color: #33A913;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day:first-child {
  border-left: 0;
}
body .react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__week:first-child .react-datepicker__day {
  border-top: 0;
}
body .react-datepicker-popper[data-placement^="bottom"],
body .react-datepicker-popper[data-placement^="top"] {
  margin: 0;
}
body .react-datepicker-wrapper {
  width: 100%;
}
