/*Less mixin*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWubEbVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWub2bVmUiA8.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v47/KFOMCnqEu92Fr1ME7kSn66aGLdTylUAMQXC89YmC2DPNWuYjalmUiA8.ttf) format('truetype');
}
/*@backgroundColor: #E9EDF2;*/
body section.login-holder,
body section.signup-holder {
  background: #ffffff;
  width: 380px;
  margin: 70px auto;
  border: 1px solid #ECECEC;
  vertical-align: middle;
  outline: none;
  position: relative;
  padding: 30px 40px;
}
body section.login-holder .or-separator,
body section.signup-holder .or-separator {
  height: 1px;
  background-color: #ECECEC;
  margin: 15px 0;
  position: relative;
}
body section.login-holder .or-separator:after,
body section.signup-holder .or-separator:after {
  content: 'OR';
  width: 16px;
  height: 16px;
  position: absolute;
  font-weight: 500;
  font-size: 0.785em;
  left: calc(50% - 13px);
  top: calc(50% - 13px);
  background-color: #ffffff;
  padding: 5px;
}
body section.login-holder .login-header .company-icon,
body section.signup-holder .login-header .company-icon {
  height: 30px;
  margin: auto;
}
body section.login-holder .login-header h3,
body section.signup-holder .login-header h3 {
  font-size: 0.92307692em;
  margin: 5px auto 15px;
  font-weight: 400;
  color: #444444;
  letter-spacing: 1.2px;
}
body section.login-holder .login-header .sub-text,
body section.signup-holder .login-header .sub-text {
  color: #33A913;
  text-align: left;
  margin: 10px 0 15px 0;
}
body section.login-holder .link,
body section.signup-holder .link {
  margin-top: 10px;
}
body section.login-holder .link a,
body section.signup-holder .link a {
  color: #33A913;
}
